// extracted by mini-css-extract-plugin
export const marketing = "Marketing-module--marketing--1yn0_";
export const header = "Marketing-module--header--1h4XZ";
export const marketing_img = "Marketing-module--marketing_img--2535P";
export const seo = "Marketing-module--seo--1cxsp";
export const feature_text = "Marketing-module--feature_text--3K5hR";
export const consultation = "Marketing-module--consultation--31BZb";
export const guidance = "Marketing-module--guidance--KBONb";
export const other_services = "Marketing-module--other_services--LnCAq";
export const services_button = "Marketing-module--services_button--1KTi1";
export const seo_review = "Marketing-module--seo_review--EBr7D";
export const grid = "Marketing-module--grid--riQwr";
export const buttons = "Marketing-module--buttons--2rrrL";
export const bouncy = "Marketing-module--bouncy--1vJBl";
export const pop = "Marketing-module--pop--2Y8bK";