import * as React from "react";
import ContactForm from "@components/ContactForm";
import OtherServices from "@components/Services/OtherServices";
import SeoReviewCard from "@components/Marketing/SeoReviewCard";
import * as styles from "./Marketing.module.scss";

import guidance from "@images/services/marketing_page/DigitalMarketingGuidance_orange.svg";

const MarketingComponent = ({metadata}) => {


  return (
  <div className={`${styles.marketing} container`}>
    <div className={styles.grid}>
      <section className={styles.header}>
        <h1>{metadata.title}</h1>
      </section>
	  {
		  metadata.offerings.map((offering, key) => {
			  if(offering.title === "seo_review"){
				  return (<section className={styles.seo_review}>
					<SeoReviewCard />
				  </section>)
			  }
			  return (
				<section className={styles[offering.style]}>
					<h2>{offering.title}</h2>
					<p className={styles.feature_text}>
					{offering.text}
					</p>
				</section>
			  )
		  })
	  }
      <img
        src={guidance}
        className={`${styles.marketing_img} ${styles.img_one}`}
      />
    </div>

    <OtherServices metadata={metadata.other_services} />

    <section>
      <ContactForm />
    </section>
  </div>
);
}

export default MarketingComponent;
