import * as React from "react";
import * as styles from "./SeoReviewCard.module.scss";

const SeoReviewCard = () => (
  <section className={styles.seo_review_card}>
    <>
      <section className={styles.form_section}>
        <div className={styles.contact_form}>
          <form
            className={styles.form_grid}
            method="post"
            action="https://formspree.io/f/xpzkqvdn"
          >
            <h2>Let's review your SEO!</h2>

            <div className={styles.with_label}>
              <label style={{ display: "block", lineHeight: "30px" }}>
                Full Name*
              </label>
              <input
                type="text"
                name="FullName"
                placeholder="John Doe"
                required
              />
            </div>
            <div className={styles.with_label}>
              <label style={{ display: "block", lineHeight: "30px" }}>
                Email*
              </label>
              <input
                type="email"
                name="Email"
                placeholder="johndoe@mail.com"
                required
              />
            </div>
            <input type="tel" name="phone" placeholder="Phone" />
            <input type="text" name="website" placeholder="Website*" required />
            <input type="submit" value="Send  Now"></input>
          </form>
        </div>
      </section>
    </>
  </section>
);

export default SeoReviewCard;
