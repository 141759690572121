import * as React from "react";
import { graphql } from "gatsby"
import Layout from "@components/layout";
import SEO from "@components/seo";
import MarketingComponent from "@components/Marketing";
import { MetadataService } from "../../utils/MetadataService";

const WebsitesPage = ({ uri, data }) => {

  const { pageMetadata } = data;
  const metadata = MetadataService.parseMetadata(JSON.parse(JSON.stringify(pageMetadata || {}))).content;

  return (
    <Layout path={uri}>
      <SEO title="Marketing | Magyk Software" />
      <MarketingComponent metadata={metadata} />
    </Layout>
  );
}


export const pageQuery = graphql`
  query MarketingServiceComponentQuery {
    pageMetadata(page_id: {eq: "services_marketing"}) {
      content,
      page_id
    }
  }
`
export default WebsitesPage;
